<template>
  <view class="index restaurant-booking">
    <!-- <van-nav-bar title="餐厅预定" /> -->
    <van-form @submit="onSubmit" class="order-form">
      <h5><van-icon name="user-o" />预定人</h5>
      <van-field class="input-field-style"
        v-model="personName"
        rows="1"
        autosize
        type="textarea"
        placeholder="请输入"
      />


      <h5><van-icon name="phone-o" />联系电话 <small class="telphone-desc">请填写常用手机号码并留意接听</small></h5>
      <van-field class="input-field-style"
        v-model="telphone"
        rows="1"
        autosize
        type="textarea"
        placeholder="请输入"
      />

      <h5><van-icon name="friends-o" />选择人数</h5>
      <div class="select-style">
        <div v-for="p in personCounts" 
            :key="p" 
            :class="isSelected(p, personCount)"
            @click="personCount = p">
            <span>{{p}}人</span>
        </div>
      </div>

      <h5><van-icon name="calendar-o" />选择日期 <small class="telphone-desc"> 往左滑动可选择可用的日期 <van-icon name="arrow" /> </small> </h5>
      <div class="select-style date-row">
        <div v-for="d in dates" 
            :key="d" 
            class= "date-box"
            :class="{
              'selected': isSelected(d.date, bookingDate),
              'is-full': !d.avaliable,
              'is-paused': d.paused,
            }"
            @click="onSelectedDate(d)">
            <span class="select-date">{{d.date}}</span>
            <span class="select-day">{{d.day}}</span>
        </div>
      </div>
        <!-- :class="isFull(d.date, bookingDate)" -->
      <!-- <van-cell title="选择单个日期" :value="date" @click="show = true" />
    <van-calendar v-model:show="show" @confirm="onConfirm" /> -->

      <h5 style="margin-top:0"><van-icon name="underway-o" />选择时间 <small class="telphone-desc"> 往左滑动可选择可用的时间 <van-icon name="arrow" /> </small></h5>
      <div class="select-style">
        <div v-for="t in times" 
            :key="t" 
            :class="isSelected(t, bookingTime)"
            @click="bookingTime = t">
            <span>{{t}}</span>
        </div>
      </div>

      <!-- <h5><van-icon name="hotel-o" />座位选择</h5>
      <div class="select-style">
        <div v-for="s in seats" 
            :key="s" 
            :class="isSelected(seat, s)"
            @click="seat = s">
            <span>{{s}}</span>
        </div>
      </div> -->

      <h5><van-icon name="flower-o" />过敏源&忌口</h5>
      <van-field class="input-field-style"
        v-model="allergy"
        rows="1"
        autosize
        type="textarea"
        placeholder="请输入"
      />
      <h5><van-icon name="smile-comment-o" />备注</h5>
       <van-field class="input-field-style"
        v-model="remark"
        rows="4"
        autosize
        type="textarea"
        placeholder="可填写忌口、节日要求等"
      />

      <van-cell class="ec-cell-desc">
        <small class="desc">订制菜均根据当天的食材配置，因此菜单内容不固定。如希望餐前了解菜单及配酒内容，敬请致电大师餐酒体验权益专属客服热线了解详情：021-61079583（服务时间为9:00-18:00）</small>
      </van-cell>
<!--       <van-cell class="ec-cell-desc">
          <small class="desc"> * 请事先了解并遵照当地及餐厅的防疫政策要求进入餐厅用餐，谢谢。 </small>
      </van-cell> -->

      <div style="margin: 16px">
        <van-button block type="primary" native-type="submit">提交</van-button>
      </div>
    </van-form>

    <van-popup v-model:show="submitSuccessShow" round position="bottom" @click="onBack" @click-overlay="onBack">
      <div class="success-card">
        <!-- <van-icon name="success" /> -->
        <!-- <h3>提交成功</h3> -->
        <div class="success-info">
          预定信息已成功提交，待商家确认后会有确认短信通知您。
        </div>
        <van-button plain type="primary" @click="onBack">我知道了</van-button>
      </div>
    </van-popup>
  </view>
</template>

<script>
import { reactive, toRefs , onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from 'vant';
import Controller from "./restaurant-booking-controller";
import "../css/restaurant-booking.scss";

export default {
  setup() {
    const state = reactive({
      avaliableBookingTimes: [],
      restaurant: {},
      restaurantId: 0,
      personCount: 2,
      personCounts: [],
      bookingDate: null,
      bookingDateObject: null,
      dates: [],
      bookingTime: null,
      times: [],
      seat: "",
      seats: [],
      remark: "",
      allergy: "",
      personName: "" ,
      telphone: "",
      submitSuccessShow: false,
      bookingOpeningHours:null,
      openingHours: null
    });
    const { id } = useRoute().params;
    const router = useRouter();
    state.restaurantId = id;
    const onSubmit = () => Controller.onSubmitToSenior(state)
        .then(() => router.push("/restaurant/" + id + "/booking-senior-custom") , err => {
          if(err.bookingDateError) {
            Toast.fail("请选择日期");
          } else if(err.bookingTimeError) {
            Toast.fail("请选择时间");
          } else if(err.bookingTelephoneError) {
            Toast.fail("请输入正确的电话号码");
          } else {
            Toast.fail("出错了，请联系相关客服。");
          }
        });
    const onBack = () => {
        state.submitSuccessShow = false;
        router.push("/restaurant/" + id);
    }
    const onSelectedDate =(value) => {
      if(value.avaliable) {
        state.bookingDate = value.date;
        state.bookingDateObject = value.dateObject;
        state.bookingTime = null;
        state.times = Controller.buildTimes(state, value.day);
      }
    }
    const isSelected = (current, value) => {
        if (!current || !value) {
            return "";
        }
        return current == value ? "selected" : "";
    }
    const onPersonCount = p => state.personCount = p
    onMounted(() => Controller.onMounted(state))
    return {
      ...toRefs(state),
      onSubmit,
      onBack,
      onPersonCount,
      onSelectedDate,
      isSelected
      //isFull
    };
  },
};
</script>
